<template>
  <div class="page-title-box">
    <div class="row align-items-center">
        
        <div class="col-sm-6">
          <div class="page-title">
            <h4>Perfil</h4>
          </div>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-right">
            <li class="breadcrumb-item"><a href="index.html">Inicio</a></li>
            <li class="breadcrumb-item ">
              <a href="javascript:void(0);">Admin</a>
            </li>
            <li class="breadcrumb-item active">
              <a href="javascript:void(0);">Perfil</a>
            </li>
          </ol>
        </div>
    </div>
    <!-- End row -->
  </div>
  <div class="row">
    <div class="col-xl-12 col-xxl-4 col-lg-4">
      <div class="row">
        <div class="col-lg-6">
          <div class="card">
            <div class="card-header">
              <h2 class="card-title">Dados Pessoais</h2>
            </div>
            <div class="card-body pb-0">
           
              <ul class="list-group list-group-flush">
                <li class="list-group-item d-flex px-0">
                  <strong>Nome Completo: </strong>
                  <span class="mb-0 ml-2">{{profileData.Nome_aluno}}</span>
                </li>
                <li class="list-group-item d-flex px-0">
                  <strong>Gênero:</strong>
                  <span class="mb-0 ml-2">{{profileData.genero_aluno}}</span>
                </li>
                  <li class="list-group-item d-flex px-0">
                  <strong>Data Nascimento:</strong>
                  <span class="mb-0 ml-2">{{profileData.dia_nas_aluno}}-{{profileData.mes_nas_aluno}}-{{profileData.ano_nas_aluno}}</span>
                </li>
                <li class="list-group-item d-flex px-0">
                  <strong>Estado Civil:</strong>
                  <span class="mb-0 ml-2">{{profileData.estadocivil}}</span>
                </li>
                <li class="list-group-item d-flex px-0">
                  <strong>Tipo de Aluno: </strong>
                  <span class="mb-0 ml-2">{{profileData.tipo_aluno}}</span>
                </li>

                
              </ul>
            </div>
          </div>
        </div>

         <div class="col-lg-6">
          <div class="card">
            <div class="card-header">
              <h2 class="card-title">Endereço</h2>
            </div>
            <div class="card-body pb-0">
           
              <ul class="list-group list-group-flush">
                <li class="list-group-item d-flex px-0 ">
                  <strong>Nacionalidade: </strong>
                  <span class="mb-0 ml-2">{{profileData.Nacionalidade_aluno}}</span>
                </li>
                <li class="list-group-item d-flex px-0 ">
                  <strong>Província: </strong>
                  <span class="mb-0 ml-2">{{profileData.provincia_aluno}}</span>
                </li>
                <li class="list-group-item d-flex px-0 ">
                  <strong>Distrito: </strong>
                  <span class="mb-0 ml-2">{{profileData.distrito_aluno}}</span>
                </li>
                  <li class="list-group-item d-flex px-0 ">
                  <strong>Bairro: </strong>
                  <span class="mb-0 ml-2">{{profileData.bairro_aluno}}</span>
                </li>
                   <li class="list-group-item d-flex px-0 ">
                  <strong>N da Casa: </strong>
                  <span class="mb-0 ml-2">{{profileData.numero_casa_aluno}}</span>
                </li>
                 
              </ul>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card">
            <div class="card-header">
              <h2 class="card-title">Contactos</h2>
            </div>
            <div class="card-body pb-0">
           
              <ul class="list-group list-group-flush">
               <li class="list-group-item d-flex px-0 ">
                  <strong>Número do telefone:</strong>
                  <span class="mb-0 ml-2">{{profileData.contacto_aluno}}</span>
                </li>
                <li class="list-group-item d-flex px-0">
                  <strong>Email:</strong>
                  <span class="mb-0 ml-2">{{profileData.email_aluno}}</span>
                </li>
               
                
              </ul>
            </div>
          </div>
        </div>
           
        
      </div>
    </div>
  
  </div>
</template>

<script setup>
import store from "@/store";
import axios from "axios";
import { onMounted, ref } from "vue";


const profileData = ref(store.state.auth.user.user_data.student_name);
 

  onMounted(()=>{
    profile();
  })


  async function profile(){

    const resp = await axios.get('https://portal.jdesign.co.mz/Api/api/profile/'+store.state.auth.user.user_data.student_id);
    profileData.value = resp.data.user_data; 
    console.log(resp.data.user_data);
  }

</script>
