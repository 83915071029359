<template>
  <div class="container-fluid">
    <div class="page-title-box">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <h4 class="page-title">Dashboard</h4>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-right">
            <li class="breadcrumb-item"><a href="#">Início</a></li>
            <li class="breadcrumb-item active">Finanças</li>
          </ol>
        </div>
      </div>
    </div>

    <div
     
      class="row"
    > 
      <div class="col-sm-6 col-xl-3">
        <div class="card">
          <router-link to="extractos">
            <div class="card">
              <div class="card-heading p-4">
                <div class="mini-stat-icon float-right">
                  <i class="mdi mdi-tag-text-outline bg-warning text-white"></i>
                </div>
                <div>
                  <h5 class="font-12">Referência por Pagar</h5>
                </div>
                <h3 class="mt-4"> 1</h3>
                <div class="progress mt-4" style="height: 4px">
                  <div
                    class="progress-bar bg-warning"
                    role="progressbar"
                    style="width: 68%"
                    aria-valuenow="68"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p class="text-muted mt-2 mb-0">
                  <!-- Previous period<span class="float-right">68%</span> -->
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div class="col-sm-6 col-xl-3">
        <div class="card">
           <router-link to="Reference_pending">
            <div class="card">
              <div class="card-heading p-4">
                <div class="mini-stat-icon float-right">
                  <i class="mdi mdi-currency-usd bg-danger text-white"></i>
                </div>
                <div>
                  <h5 class="font-12">Mês em Atraso</h5>
                </div>
                <h3 class="mt-4">{{getMonthPandingdLength}}</h3>
                <div class="progress mt-4" style="height: 4px">
                  <div
                    class="progress-bar bg-danger"
                    role="progressbar"
                    style="width: 88%"
                    aria-valuenow="88"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p class="text-muted mt-2 mb-0"></p>
              </div>
            </div>
          </router-link>
        </div>
      </div> 

    
      <div class="col-sm-6 col-xl-3">
        <div class="card">
           <router-link to="References_Month_payd">
            <div class="card">
              <div class="card-heading p-4">
                <div class="mini-stat-icon float-right">
                  <i class="mdi mdi-currency-usd bg-success text-white"></i>
                </div>
                <div>
                  <h5 class="font-12">Extractos \ Referências Mensais Pagas</h5>
                </div>
                <h3 class="mt-4">{{getMonthPaydLength}}</h3>
                <div class="progress mt-4" style="height: 4px">
                  <div
                    class="progress-bar bg-success"
                    role="progressbar"
                    style="width: 88%"
                    aria-valuenow="88"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p class="text-muted mt-2 mb-0"></p>
              </div>
            </div>
          </router-link>
        </div>
      </div> 


      <!-- 
      <swiper-slide class="col-sm-6 col-xl-3">
        <div class="card">
           <router-link to="other_payments">
            <div class="card">
              <div class="card-heading p-4">
                <div class="mini-stat-icon float-right">
                  <i class="mdi mdi-currency-usd bg-success text-white"></i>
                </div>
                <div>
                  <h5 class="font-16">Outros Pagamentos</h5>
                </div>
                <h3 class="mt-4">{{ getOtherPaymentsLength }}</h3>
                <div class="progress mt-4" style="height: 4px">
                  <div
                    class="progress-bar bg-success"
                    role="progressbar"
                    style="width: 88%"
                    aria-valuenow="88"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p class="text-muted mt-2 mb-0"></p>
              </div>
            </div>
          </router-link>
        </div>
      </swiper-slide> -->


      <div class="col-sm-6 col-xl-3">
        <div class="card">
          <router-link to="otherReferencesPayed">
            <div class="card">
              <div class="card-heading p-4">
                <div class="mini-stat-icon float-right">
                  <i class="mdi mdi-calendar-check bg-primary text-white"></i>
                </div>
                <div>
                  <h5 class="font-12">Outras Referências Pagas</h5>
                </div>
                <h3 class="mt-4">{{ Otherpayments }}</h3>
                <div class="progress mt-4" style="height: 4px">
                  <div
                    class="progress-bar bg-primary"
                    role="progressbar"
                    style="width: 75%"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p class="text-muted mt-2 mb-0">
                  <!-- Previous period<span class="float-right">75%</span> -->
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div class="col-sm-6 col-xl-3">
        <div class="card">
          <router-link to="otherReferencesNotPayed">
            <div class="card">
              <div class="card-heading p-4">
                <div class="mini-stat-icon float-right">
                  <i class="mdi mdi-currency-usd bg-danger text-white"></i>
                </div>
                <div>
                  <h5 class="font-12">Outras Referências Não Pagas</h5>
                </div>
                <h3 class="mt-4">{{ otherReferencesNotPayedLength }}</h3>
                <div class="progress mt-4" style="height: 4px">
                  <div
                    class="progress-bar bg-danger"
                    role="progressbar"
                    style="width: 88%"
                    aria-valuenow="88"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p class="text-muted mt-2 mb-0"></p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
<!-- 
      <swiper-slide class="col-sm-6 col-xl-3">
        <div class="card">
          <router-link to="ReferencesMonthNotPayed">
            <div class="card">
              <div class="card-heading p-4">
                <div class="mini-stat-icon float-right">
                  <i class="mdi mdi-currency-usd bg-danger text-white"></i>
                </div>
                <div>
                  <h5 class="font-16">Referências Mensais Não Pagas</h5>
                </div>
                <h3 class="mt-4">{{ MouthNotpayments }}</h3>
                <div class="progress mt-4" style="height: 4px">
                  <div
                    class="progress-bar bg-danger"
                    role="progressbar"
                    style="width: 88%"
                    aria-valuenow="88"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p class="text-muted mt-2 mb-0"></p>
              </div>
            </div>
          </router-link>
        </div>
      </swiper-slide> -->
    </div>

    <!-- START ROW -->
    <router-view></router-view>

    <!-- END ROW -->
  </div>
</template>

<script setup>
import store from "@/store";
import axios from "axios";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
// import required modules
import "swiper/css/pagination";

import { Pagination } from "swiper/modules";

const modules = ref([Pagination]);
const apiUrl = process.env.VUE_APP_API_URL

const Totalextractos = ref(0);
const MouthNotpayments = ref(0);
const Otherpayments = ref(0);
const otherReferencesNotPayedLength = ref(0);
const getMonthPaydLength = ref(0);
const getMonthPandingdLength = ref(0);

const router = useRouter();
onMounted(() => {
  getExtractos();
  ReferencesMonthNotPayed();
  OtherReferencesPayed();
  otherReferencesNotPayed();
  getOtherPayments();
  getReferencePanding();
});

async function getReferencePanding() {
  
    const resp = await axios.get(apiUrl+`ReferencesFine?student_id=${store.state.auth.user.user_data.student_id}&LoggedYear=${store.state.auth.LoggedYear}`
    );
    getMonthPandingdLength.value = resp.data.result.length;

    
}
async function getExtractos() {
  const resp = await axios.get(apiUrl+`Extracto?student_id=` +
      store.state.auth.user.user_data.student_id+"&LoggedYear="+store.state.auth.LoggedYear
  );
  // extractos.value = resp.data.total;\
  Totalextractos.value = resp.data.result.total;
  console.log(resp.data.result.total);
}

async function ReferencesMonthNotPayed() {
  const resp = await axios.get(apiUrl+`ReferencesMonthNotPayed?student_id=` +
      store.state.auth.user.user_data.student_id+"&LoggedYear="+store.state.auth.LoggedYear
  );
  MouthNotpayments.value = resp.data.result.total;
}

async function OtherReferencesPayed() {
  const resp = await axios.get(apiUrl+`OtherReferencesPayed?student_id=` +
      store.state.auth.user.user_data.student_id+"&LoggedYear="+store.state.auth.LoggedYear
  );
  Otherpayments.value = resp.data.result.total;
}

async function otherReferencesNotPayed() {
  const resp = await axios.get(apiUrl+`otherReferencesNotPayed?student_id=` +
      store.state.auth.user.user_data.student_id+"&LoggedYear="+store.state.auth.LoggedYear
  );
  otherReferencesNotPayedLength.value = resp.data.result.total;
}

async function getOtherPayments() {
  const resp = await axios.get(apiUrl+`ExtractoPayd?student_id=` +
      store.state.auth.user.user_data.student_id+"&LoggedYear="+store.state.auth.LoggedYear
  );
  getMonthPaydLength.value = resp.data.result.length;
}

function Callextractos() {
  router.push("/extractos");
}
function referenciasMensais() {
  router.push("/other_references");
}

function moneyFormat(money) {
  const formatter = new Intl.NumberFormat("MZ", {
    style: "currency",
    currency: "MZN",
  });

  return formatter.format(money);
}
</script>
 