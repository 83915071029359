import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { MainLayout } from "@/layouts";
import MyHome from "@/components/MyHome.vue";
import MyLogin from "@/pages/login/MyLogin.vue";
import MyInscriptions from "@/pages/inscriptions/MyInscriptions.vue";
import MyProfile from "@/pages/profile/MyProfile.vue";
import ReferencesMonthPayd from "@/components/ReferencesMonthPayd.vue";
import OtherReferencesNotPayed from "@/components/OtherReferencesNotPayed.vue";
import OtherReferencesPayed from "@/components/OtherReferencesPayed.vue";
import ReferencesMonthNotPayed from "@/components/ReferencesMonthNotPayed.vue";
import Reference_pending from "@/components/Reference_pending.vue"; 
import Extratos from "@/components/Extratos.vue";
import Recover_password from "@/pages/recover_password/Recover_password.vue"; 
import update_password from "@/pages/recover_password/update_password.vue";  

const routes = [
  {
    path: "/login",
    name: "login",
    component: MyLogin,
    meta: {
      middleware: "guest",
      title: `Login`,
    },
  },

  {
    path: "/forgot-password",
    name: "inscription",
    component: Recover_password,
    meta: {
      middleware: "guest",
      title: "Inscription",
    },
  },

  {
    path: "/",
    component: MainLayout,
    meta: {
      middleware: "auth",
      middleware: "auth",
      requiresAuth: true,
      title:"Sistema de situação financeira",

    },
    children: [
      {
        path: "/",
        name: "dashboard",
        component: MyHome,
        children: [
          {
            path: "/",
            component: Extratos,
          },
          {
            path: "/extractos",
            component: Extratos,
          }, 
          {
            path: "/References_Month_payd",
            component: ReferencesMonthPayd,
          },
          {
            path: "/otherReferencesNotPayed",
            component: OtherReferencesNotPayed,
          },
          {
            path: "/otherReferencesPayed",
            component: OtherReferencesPayed,
          },
          {
            path: "/referencesMonthNotPayed",
            component: ReferencesMonthNotPayed,
          },
          {
            path:'/Reference_pending',
            component:Reference_pending
          }
         
       
        ],
      },
      {
        path:'/update_password',
        component:update_password
      },

      {
        path: "/profile",
        component: MyProfile,
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (to.meta.requiresAuth && !isAuthenticated()) {
    // redirect user to the login page
    next("/login");
  } else {
    //allow user to access user his role
    next();
  }
 
});

function isAuthenticated() {
  if (store.state.auth.authenticated) {
    return true;
  } else {
    return false;
  }
  // return true;
}

function hasUserRole(role) {
  if (isAuthenticated()) {
    if (store.state.auth.user.role == role) {
      return true;
    } else {
      return false;
    }
  } 
}
export default router;
