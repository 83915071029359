<template>
  <div class="left side-menu">
    <div class="slimscroll-menu" id="remove-scroll">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu" id="side-menu">
          <li class="menu-title">Menu</li>
          <li>
            <router-link to="/" class="waves-effect">
              <i class="icon-accelerator"></i
              ><span class="badge badge-success badge-pill float-right"
                ></span
              >
              <span> Dashboard </span>
            </router-link>
          </li>
          <li>
            <router-link to="/profile" class="waves-effect"
              ><i class="mdi mdi-account-circle"></i
              ><span>
                Perfil
            </span
            ></router-link> 
          </li>

          <li>
            <router-link to="/update_password" class="waves-effect"
              ><i class="mdi mdi-account-circle"></i
              ><span>
                Alterar Senha
            </span
            ></router-link> 
          </li>
 
        
      
        </ul>
      </div>
      <!-- Sidebar -->
      <div class="clearfix"></div>
    </div>
    <!-- Sidebar -left -->
  </div>
</template>

<script>
 export default {

 };
</script>
