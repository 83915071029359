<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title">
          <h4>Perfil</h4>
        </div>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="index.html">Inicio</a></li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0);">Admin</a>
          </li>
          <li class="breadcrumb-item active">
            <a href="javascript:void(0);">Perfil</a>
          </li>
        </ol>
      </div>
    </div>
    <!-- End row -->
  </div>

  <div class="authincation h-100">
    <div class="container h-100">
      <div class="car col-lg-8 mx-auto m-2">
        <div class="row justify-content-center h-100 p-5 align-items-center">
          <div class="card col-md-6">
            <div class="authincation-content">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="auth-form">
                    <h4 class="text-center mb-4">Actualizar a senha</h4>
                    <form @submit.prevent="handleSubmitButton">
                      <div class="form-group">
                        <label class="ml-3"
                          ><strong>Senha Actual</strong></label
                        >
                        <input
                          type="password"
                          class="form-control col-lg-11 mx-auto"
                          v-model="data.senha_actual"
                        />
                      </div>
                      <div class="form-group">
                        <label class="ml-3"><strong>Nova Senha</strong></label>
                        <input
                          type="password"
                          class="form-control col-lg-11 mx-auto"
                          v-model="data.nova_senha"
                        />
                      </div>
                      <div class="form-group">
                        <label class="ml-3"
                          ><strong>Confirmar Senha</strong></label
                        >
                        <input
                          type="password"
                          class="form-control col-lg-11 mx-auto"
                          v-model="data.confirmar_senha"
                        />
                      </div>
                      <div
                        class="form-row d-flex justify-content-between mt-4 mb-2 col-lg-11"
                      ></div>
                      <div class="text-center">
                        <button
                          type="button mx-auto"
                          class="btn-block"
                          style="
                            background-color: #ed7431;
                            color: #fff;
                            border: none;
                            border-radius: 6px;
                            padding: 8px;
                          "
                          v-on:click="handleSubmitButton"
                          v-if="loading == false"
                        >
                          Actualizar
                        </button>

                        <button
                          v-else
                          type="button mx-auto"
                          class="btn-block"
                          style="
                            background-color: #ed7431;
                            color: #fff;
                            border: none;
                            border-radius: 6px;
                            padding: 8px;
                          "
                        >
                          Actualizando...
                        </button>
                      </div>
                    </form>
                    <div class="new-account mt-3">
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import toastr from "toastr";
import axios from "axios";
import store from '@/store';
export default {
  data() {
    return {
      data: {
        senha_actual: "",
        nova_senha: "",
        confirmar_senha: "",
        student_id: store.state.auth.user.user_data.student_id
      },
      loading: false,
    };
  },

  methods: {
    async handleSubmitButton() {
      this.loading = true;

      if (
        this.data.nova_senha == "" ||
        this.data.confirmar_senha == "" ||
        this.data.senha_actual == ""
      ) {
                this.loading = false;

        return toastr.info("Por favor preencha todos os campos");
      }

      if (this.data.nova_senha != this.data.confirmar_senha) {
                this.loading = false;

        return toastr.info("Por favor confirme a senha");
      }
      axios
        .post("https://portal.jdesign.co.mz/Api/api/update_password", this.data)
        .then(({ data }) => {
 
            if (data.current_password == 0) {
                
                toastr.error("Desculpe-nos mas a sua senha actual não  foi confirmada...");
            }else if(data == 1){
                toastr.success("A sua senha foi alterada com sucesso...");

                this.data.senha_actual = "";
                this.data.nova_senha = "";
                this.data.confirmar_senha = "";

            }else if(data == 0){
                toastr.error("Desculpe-nos ocorreu um erro ao tentar actualizar  a sua senha tente mais tarde!");
            }

        })
        .catch((response) => {
          toastr.error(
            "Erro de autenticação  verifique suas credenciais ou contacte o administrador" +
              response.data
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
