import { createApp } from 'vue'
// import Home_main from './components/home_main.vue'
import AppMain from './AppMain.vue' 
import Router from '@/router'
import store from '@/store'
import toastr from 'toastr'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import { VueSelect } from "vue-select/dist/vue-select.es";

// import { library } from "@fortawesome/fontawesome-svg-core";
// import { faPhone } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

 

import "vue-select/dist/vue-select.css";
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'


  
axios.defaults.withCredentials = true;


// const app = createApp({});
// // app.component(Home_main)
// app.mount("#app")

const app = createApp(AppMain);
    // app.component("v-select", VueSelect)
    app.use(ViewUIPlus);
    app.use(VueAxios, axios)
    app.use(Router)
    app.use(toastr)
    app.use(store)
 
    app.mount("#app");
