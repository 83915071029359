<template>
  <div class="container-fluid">
    <!-- START ROW -->
    <div class="row">
      <div class="col-xl-12">
        <div class="card m-b-30">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th>Propina</th>
                    <th scope="col">Entidade</th>
                    <th scope="col">Referência</th>
                    <th scope="col">Valor</th>
                     <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(p, index) in payments.data"
                    :key="index"
                    :class="{ facturaNaoPaga: p.EstadoFIC != 'Pago' }"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ p.PropinaFIC }}</td>
                    <td>{{ p.EntidadeFIC }}</td>
                    <td>{{ p.ReferenciaFIC }}</td>
                    <td>{{ moneyFormat(p.ValorAPagar) }}</td>
                  

                    <td v-if="p.EstadoFIC == 'Pago'">
                      <span
                        class="badge badge-success"
                        style="cursor: pointer"
                        @click="generateReceipt(p.ReferenciaFIC, p.TurmaC)"
                        >Gerar Referência</span
                      >
                    </td>
                    <td v-else>-------</td>
                  </tr>
                </tbody>
              </table>
              <div v-if="payments.total == 0" class="text-center">
                Ainda sem informação
              </div>
              <div class="text-center" v-if="loadingData">
                <img
                  src="assets/images/loading.gif"
                  width="45"
                  height="45"
                  alt=""
                />
              </div>
              <Page
                :total="payments.total"
                :model-value="payments.current_page"
                :page-size="payments.per_page"
                @on-change="ReferencesMonthNotPayed"
                v-if="payments.total"
                class="float-right"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END ROW -->
  </div>
</template>

<script setup>
import store from "@/store";
import axios from "axios";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const payments = ref([]);
const router = useRouter();
const loadingData = ref(false);
const apiUrl = process.env.VUE_APP_API_URL

onMounted(() => {
  ReferencesMonthNotPayed();
});

async function ReferencesMonthNotPayed(page = 1) {
  loadingData.value = true;

  const resp = await axios.get(apiUrl+`ReferencesMonthNotPayed?page=${page}&student_id=${store.state.auth.user.user_data.student_id}&LoggedYear=${store.state.auth.LoggedYear}`
  );
  payments.value = resp.data.result;
  loadingData.value = false;
}

function Callextractos() {
  router.push("/extractos");
}
function referenciasMensais() {
  router.push("/other_references");
}

function moneyFormat(money) {
  const formatter = new Intl.NumberFormat("MZ", {
    style: "currency",
    currency: "MZN",
  });

  return formatter.format(money);
}

async function generateReceipt(referencesFic, TurmaC) {
  const dserv = await getDesignacaoCurso(TurmaC);
  const dservServico = await getDesignacaoServico(TurmaC);
  var t = "";

  if (dserv == "" || dserv == null) {
    t = dservServico;
  } else {
    t = dserv;
  }

  // const url = `https://gestao.jdesign.co.mz/SGPR-IPPC/view/control/RECIBO.php?ref=${referencesFic}&dserv=${t}`;
    const url = `https://gestao.sgpr-ippc.com/SGPR-IPPC/view/control/REFERENCIA_PARA_PAGAMENTO.php?ref=${referencesFic}`;

  window.open(url);
}

async function getDesignacaoCurso(TurmaC) {
  const resp = await axios.get(apiUrl+`getDesignacaoCurso/${TurmaC}`
  );
  if (resp.data.result == null) {
    return null;
  } else {
    return resp.data.result.designacao;
  }
}

async function getDesignacaoServico(TurmaC) {
  const resp = await axios.get(apiUrl+`getDesignacaoServico/${TurmaC}`
  );

  if (resp.data.result == null) {
    return null;
  } else {
    return resp.data.result.Descricao_servico;
  }

}


function getMonth(mes) {
  switch (mes) {
    case 1:
      return "Janeiro";
    case 2:
      return "Fevereiro";
    case 3:
      return "Março";
    case 4:
      return "Abril";
    case 5:
      return "Maio";
    case 6:
      return "Junho";
    case 7:
      return "Julho";
    case 8:
      return "Agosto";
    case 9:
      return "Setembro";
    case 10:
      return "Outubro";
    case 11:
      return "Novembro";
    case 12:
      return "Dezembro";
    default:
      return "";
  }

}
</script>


<style scoped>
.facturaNaoPaga {
  background: #fc5454;
  color: #ffffff;
}
</style>