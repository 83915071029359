<template> 

  <div class="authincation h-100 my-auto">
    <div class="container h-100 ">
      <div class="card col-lg-8 mx-auto" style="margin-top: 10em;">
        <div class="row justify-content-center h-100 p-5 ">
          <div class="col-md-6">
            <div class="authincation-content align-items-center">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="auth-form">
                    <!-- <div class="text-center mb-5 mt-4">
                        <img src="assets/images/logo.jpeg" alt="" />
                      </div> -->
                    <!-- <h4 class="text-center mb-4">Acesse a sua conta</h4> -->
                    <form>
                       <div class="form-group">
                        <label><strong>Email</strong></label>
                        <input type="email" class="form-control" v-model="data.email" />
                      </div>

                      <div class="text-center">
                        <button type="button" id="btn_reset" class="btn-block" style="
                              background-color: #e99239;
                              color: #fff;
                              border: none;
                              border-radius: 6px;
                              padding: 8px;
                            "
                            v-if="loading==false"
                            @click.prevent="handleSubmitButton">
                          Enviar
                        </button>

                         <button v-else type="button" id="btn_reset" class="btn-block" style="
                              background-color: #e99239;
                              color: #fff;
                              border: none;
                              border-radius: 6px;
                              padding: 8px;
                            ">
                            Enviando...
                        </button>
                      </div>
                    </form>
                    <div class="new-account mt-3">
                      <p>
                        Acesse a sua conta?
                        <router-link style="color: #0093dd" to="/login">Clique aqui</router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
 
 </template>
 
<script>
import { mapActions } from "vuex";
import toastr from "toastr";
import axios from "axios";
 export default {
  data() {
    return {
      data: {
        email: "",
       },
      loading: false,
    };
  },

  methods: {
    async handleSubmitButton() {
      this.loading = true;

      if (
        this.data.email == ""
        ) {
        this.loading = false;

        return toastr.info("Por favor preencha o campo");
      }

      
      axios
        .post("https://portal.jdesign.co.mz/Api/api/recover_password", this.data)
        .then(({ data }) => {
          console.log(data);

        })
        .catch((response) => {
          toastr.error(
            "Erro de autenticação  verifique suas credenciais ou contacte o administrador" +
              response.data
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
