<template>
  <div class="container-fluid">
    <!-- START ROW -->
    <div class="row">
      <div class="col-xl-12">
        <div class="card m-b-30">
          <div class="card-body">
          <div
              class="form-group app-search float-left"
              v-if="payments.length > 0"
            >
              <h4
                class="mt-0 header-title mb-4 form-control"
                style="width: 250px"
              >
                Extrair Extracto
              </h4>
              <button
                @click="printExtracto"
                class="text-danger"
                style="margin-top: -6px; cursor: pointer"
              >
                <i style="font-size: 18px" class="mdi mdi-printer"></i>
              </button>
            </div>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th>Propina</th>
                    <th scope="col">Entidade</th>
                    <th scope="col">Referência</th>
                    <th scope="col">Valor</th>
                     <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(p, index) in payments"
                    :key="index"
                    :class="{ facturaNaoPaga: p.EstadoFIC != 'Pago' }"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ p.PropinaFic }}</td>
                    <td>{{ p.EntidadeFIC }}</td>

                    <td>{{ p.ReferenciaFIC }}</td>
                    <td>{{ moneyFormat(p.ValorAPagar) }}</td>
                
                    <td v-if="p.EstadoFIC == 'Pago'">
                      <span
                        class="badge badge-success"
                        style="cursor: pointer"
                        @click="generateReceipt(p.ReferenciaFIC, p.TurmaC, p.CodigoIC)"
                        >Gerar Recibo</span
                      >
                    </td>
                    <td v-else>
                       <span
                        class="badge badge-success"
                        style="cursor: pointer"
                        @click="generateRefPay(p.ReferenciaFIC, p.TurmaC)"
                        >Gerar Referência</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="payments.total == 0" class="text-center">
                Ainda sem informação
              </div>
              <div class="text-center" v-if="loadingData">
                <img
                  src="assets/images/loading.gif"
                  width="45"
                  height="45"
                  alt=""
                />
              </div>
              <Page
                :total="payments.total"
                :model-value="payments.current_page"
                :page-size="payments.per_page"
                @on-change="getOtherPayments"
                v-if="payments.total"
                class="float-right"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END ROW -->
  </div>
</template>

<script setup>
import store from "@/store";
import axios from "axios";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const payments = ref([]);
// const extractos = ref([])
const loadingData = ref(false);
const apiUrl = process.env.VUE_APP_API_URL

const router = useRouter();
onMounted(() => {
  getReferencesPayd();
  // getExtractos()
});

async function getReferencesPayd(page = 1) {
  loadingData.value = true;

  const resp = await axios.get(apiUrl+`ExtractoPayd?page=${page}&student_id=${store.state.auth.user.user_data.student_id}&LoggedYear=${store.state.auth.LoggedYear}`

  );
 
  console.log("server===", resp.data.result);
  payments.value = resp.data.result;
  loadingData.value = false;
}

async function printExtracto() {
  const studentId = store.state.auth.user.user_data.student_id;
  const year = store.state.auth.user.user_data.inscription_year;
  const agency = store.state.auth.user.user_data.agency;

  const resp = await axios.post(apiUrl+`getTipoExtracto`,
    { student_id: studentId, year: year }
  );

  const url = `https://gestao.sgpr-ippc.com/SGPR-IPPC/view/control/ExtractoAluno.php?ano=${year}&estu1=${studentId}&ag=${agency}&tipo=${resp.data.type}, 'Impressao' , 'width=900,height=850,scrollbars=yes'`;
  window.open(url);
}


//   async function getExtractos(){

//   const resp = await axios.get('https://portal.sgpr-ippc.com/Api/api/Extracto?student_id='+store.state.auth.user.user_data.student_id);
//   extractos.value = resp.data.result;
//  }

function Callextractos() {
  router.push("/extractos");
}
function referenciasMensais() {
  router.push("/other_references");
}

function moneyFormat(money) {
  const formatter = new Intl.NumberFormat("MZ", {
    style: "currency",
    currency: "MZN",
  });

  return formatter.format(money);
}

async function generateReceipt(referencesFic, TurmaC, CodigoIC) {
  const dserv = await getDesignacaoCurso(TurmaC);
  const dservServico = await getDesignacaoServico(TurmaC);

  var t = "";

  if (dserv == "" || dserv == null) {
    t = dservServico;
  } else {
    t = dserv;
  }

  // console.log("tt===", t);
    const url = `https://gestao.sgpr-ippc.com/SGPR-IPPC/view/control/RECIBO.php?ref=${referencesFic}&dserv=${t}`;

  // const url = `https://gestao.jdesign.co.mz/SGPR-IPPC/view/control/RECIBO.php?ins=${CodigoIC}`;
  window.open(url);
}



async function generateRefPay(referencesFic, TurmaC){
   const dserv = await getDesignacaoCurso(TurmaC);
  const dservServico = await getDesignacaoServico(TurmaC);

  var t = "";

  if (dserv == "" || dserv == null) {
    t = dservServico;
  } else {
    t = dserv;
  }

  const url = `https://gestao.sgpr-ippc.com/SGPR-IPPC/view/control/REFERENCIA_PARA_PAGAMENTO.php?ref=${referencesFic}`;
  window.open(url);

}




// Beckup
// async function generateReceipt(referencesFic, TurmaC) {
//   const dserv = await getDesignacaoCurso(TurmaC);
//   const dservServico = await getDesignacaoServico(TurmaC);

//   var t = "";

//   if (dserv == "" || dserv == null) {
//     t = dservServico;
//   } else {
//     t = dserv;
//   }

//   // console.log("tt===", t);
//   const url = `https://gestao.jdesign.co.mz/SGPR-IPPC/view/control/RECIBO.php?ref=${referencesFic}&dserv=${t}`;
//   window.open(url);
// }

async function getDesignacaoCurso(TurmaC) {
  const resp = await axios.get(apiUrl+`getDesignacaoCurso/${TurmaC}`
  );

  console.log(resp);
  if (resp.data.result == null) {
    return null;
  } else {
    return resp.data.result.designacao;
  }
}

async function getDesignacaoServico(TurmaC) {
  const resp = await axios.get(apiUrl+`getDesignacaoServico/${TurmaC}`
  );

  console.log(resp.data);
  if (resp.data.result == null) {
    return null;
  } else {
    return resp.data.result.Descricao_servico;
  }
}
</script>


 <style scoped>
.facturaNaoPaga {
  background: #fc5454;
  color: #ffffff;
}
</style>