<template>
  <div class="container-fluid">
    <!-- START ROW -->
    <div class="row">
      <div class="col-xl-12">
        <div class="card m-b-30">
          <div class="card-body">
            <!-- <div
              class="form-group app-search float-left"
              v-if="extractos.length > 0"
            >
              <h4
                class="mt-0 header-title mb-4 form-control"
                style="width: 250px"
              >
                Extrair Extracto
              </h4>
              <button
                @click="printExtracto"
                class="text-danger"
                style="margin-top: -6px; cursor: pointer"
              >
                <i style="font-size: 18px" class="mdi mdi-printer"></i>
              </button>
            </div> -->

            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Entidade</th>
                    <th>Referência</th>
                    <th>Mês</th>
                    <th>Data Limite</th>
                    <th>Valor</th>
                    <th>Propina</th>
                     <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(ex, index) in extractos"
                    :class="{ facturaNaoPaga: ex.EstadoFIC != 'Pago' }"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ ex.EntidadeFIC }}</td>
                    <td>{{ ex.ReferenciaFIC }}</td>
                    <td>{{ getMonth(ex.MesFIC) }}</td>
                    <td>{{ ex.DataLimitFIC }}</td>
                    <td>{{ moneyFormat(ex.ValorAPagar) }}</td>
                    <td>{{ ex.PropinaFic }}</td>
                   

                    <td v-if="ex.EstadoFIC == 'Nao Pago'">
                      <span
                        class="badge badge-success"
                        style="cursor: pointer"
                        @click="generateReceipt(ex.ReferenciaFIC, ex.TurmaC)"
                        >Gerar Referências </span
                      >
                    </td>
                    <td v-else>-------</td>
                  </tr>
                </tbody>
              </table>
              <div v-if="extractos.length == 0" class="text-center">
                Ainda sem informação
              </div>

              <div class="text-center" v-if="loadingData">
                <img
                  src="assets/images/loading.gif"
                  width="45"
                  height="45"
                  alt=""
                />
              </div>

              <!-- <Page
                :total="extractos.total"
                :model-value="extractos.current_page"
                :page-size="extractos.per_page"
                @on-change="getExtractos"
                v-if="extractos.total"
                class="float-right"
              /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END ROW -->
  </div>
</template>

<script setup>
import store from "@/store";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
// import required modules
import "swiper/css/pagination";

import { Pagination } from "swiper/modules";

const modules = ref([Pagination]);
const apiUrl = process.env.VUE_APP_API_URL

const extractos = ref([]);
const router = useRouter();
const loadingData = ref(false);
onMounted(async () => {
  getExtractos();
});


async function getExtractos(page = 1) {
  const dataAtual = new Date();

  var dia = String(dataAtual.getDate()).padStart(2, "0");
  var mes = String(dataAtual.getMonth() + 1).padStart(2, "0"); // Os meses em JavaScript são indexados a partir de 0
  var ano = dataAtual.getFullYear();

  var primeiroDiaDoMes = new Date(
    dataAtual.getFullYear(),
    dataAtual.getMonth(),
    1
  );

  // Fazer a diferenca do primeiro dia da data actual e data actual do mes ex: ((01-02-2023) - (01-022023)) para encontrar o resto dos dias actuais
  var diaCurr = String(primeiroDiaDoMes.getDate()).padStart(2, "0");
  var mesCurr = String(primeiroDiaDoMes.getMonth() + 1).padStart(2, "0"); // Os meses em JavaScript são indexados a partir de 0
  var anoCurr = primeiroDiaDoMes.getFullYear();
  const Currentdate = new Date(`${ano}/${mes}/${dia}`);

  var FirstDateOfMonth = new Date(`${anoCurr}/${mesCurr}/${diaCurr}`);

  var difdateCurrentMill = Math.abs(Currentdate - FirstDateOfMonth);
  var diferenceDaysCurrent = Math.ceil(
    difdateCurrentMill / (1000 * 60 * 60 * 24)
  );

//   console.log("Resto dos dias da data actual===", diferenceDaysCurrent);

    var ano =  dataAtual.getFullYear(); // Substitua pelo ano desejado
    var mes = dataAtual.getMonth();    // Substitua pelo mês desejado (0 a 11, onde 0 é janeiro e 11 é dezembro)

    var ultimoDia = ultimoDiaDoMes(ano, mes);
    console.log(diferenceDaysCurrent);

   if(diferenceDaysCurrent <= 10){

      console.log("dentro de = ", diferenceDaysCurrent);
            var d1 = `${anoCurr}-${mesCurr}-${diaCurr}`;
            var mes = String(dataAtual.getMonth() + 1).padStart(2, "0"); // Os meses em JavaScript são indexados a partir de 0
            var year =  dataAtual.getFullYear();
            var d2 = `${year}-${mes}-10`;
 
    const resp = await axios.get(apiUrl+`Extracto?page=${page}&student_id=${store.state.auth.user.user_data.student_id}&LoggedYear=${store.state.auth.LoggedYear}&d1=${d1}&d2=${d2}`
    );
    extractos.value = resp.data.result;

   
    console.log("Estado de pagamento =", resp.data.result);

    if(resp.data.result[0].EstadoFIC === "Pago"){
      console.log("Vou passar para outra data e trazer outra factura");
  
      getOtherFactura(11, 15);
    }
    loadingData.value = false;

   }else if(diferenceDaysCurrent > 10 && diferenceDaysCurrent <= 15){

      console.log("dentro de = 15", diferenceDaysCurrent);
           var d1 = `${anoCurr}-${mesCurr}-11`;
            var mes = String(dataAtual.getMonth() + 1).padStart(2, "0"); // Os meses em JavaScript são indexados a partir de 0
            var year =  dataAtual.getFullYear();
            var d2 = `${year}-${mes}-15`;

       const resp = await axios.get(apiUrl+`Extracto?page=${page}&student_id=${store.state.auth.user.user_data.student_id}&LoggedYear=${store.state.auth.LoggedYear}&d1=${d1}&d2=${d2}`
    );
    extractos.value = resp.data.result;


    if(resp.data.result[0].EstadoFIC === "Pago"){
      console.log("Vou passar para outra data e trazer outra factura");
  
      getOtherFactura(16, ultimoDia);
    }


    loadingData.value = false;

  }else if(diferenceDaysCurrent > 15 && diferenceDaysCurrent <= ultimoDia){
      console.log("dentro de = ", diferenceDaysCurrent);

             var d1 = `${anoCurr}-${mesCurr}-16`;
            var mes = String(dataAtual.getMonth() + 1).padStart(2, "0"); // Os meses em JavaScript são indexados a partir de 0
            var year =  dataAtual.getFullYear();
            var d2 = `${year}-${mes}-${ultimoDia}`;

            console.log("Ultimo dia", ultimoDia);
   const resp = await axios.get(apiUrl+`Extracto?page=${page}&student_id=${store.state.auth.user.user_data.student_id}&LoggedYear=${store.state.auth.LoggedYear}&d1=${d1}&d2=${d2}`
    );
    extractos.value = resp.data.result;

    // if(resp.data.result[0].EstadoFIC === "Pago"){
    //   console.log("Vou passar para outra data e trazer outra factura");
  
    //   getOtherFactura(16, ultimoDia);
    // }

    loadingData.value = false;
  }

}

// Pega outra factura
async function getOtherFactura(day1, day2){
  const dataAtual = new Date();
   var primeiroDiaDoMes = new Date(
    dataAtual.getFullYear(),
    dataAtual.getMonth(),
    1
  );

  // Fazer a diferenca do primeiro dia da data actual e data actual do mes ex: ((01-02-2023) - (01-022023)) para encontrar o resto dos dias actuais
  var diaCurr = String(primeiroDiaDoMes.getDate()).padStart(2, "0");
  var mesCurr = String(primeiroDiaDoMes.getMonth() + 1).padStart(2, "0"); // Os meses em JavaScript são indexados a partir de 0
  var anoCurr = primeiroDiaDoMes.getFullYear();


        console.log("de", d1 + "ate "+ d2);
            var d1 = `${anoCurr}-${mesCurr}-${day1}`;
            var mes = String(dataAtual.getMonth() + 1).padStart(2, "0"); // Os meses em JavaScript são indexados a partir de 0
            var year =  dataAtual.getFullYear();
            var d2 = `${year}-${mes}-${day2}`;

    const resp = await axios.get(apiUrl+`Extracto?student_id=${store.state.auth.user.user_data.student_id}&LoggedYear=${store.state.auth.LoggedYear}&d1=${d1}&d2=${d2}`
    );
    extractos.value = resp.data.result;
    loadingData.value = false;
 

}

function ultimoDiaDoMes(ano, mes) {
  return new Date(ano, mes + 1, 0).getDate();
}


const referenciaActual = computed(() => {
  const dataAtual = new Date("2023/02/15");

  var dia = String(dataAtual.getDate()).padStart(2, "0");
  var mes = String(dataAtual.getMonth() + 1).padStart(2, "0"); // Os meses em JavaScript são indexados a partir de 0
  var ano = dataAtual.getFullYear();

  return extractos.value.filter((item) => {
    const Currentdate = new Date(`${ano}/${mes}/${dia}`);
    const DateLimite = new Date(item.DataLimitFIC);
    const limiteDay = String(DateLimite.getDate()).padStart(2, "0");

    var primeiroDiaDoMes = new Date(
      dataAtual.getFullYear(),
      dataAtual.getMonth(),
      1
    );

    // Fazer a diferenca do primeiro dia da data actual e data actual do mes ex: ((01-02-2023) - (01-022023)) para encontrar o resto dos dias actuais
    var diaCurr = String(primeiroDiaDoMes.getDate()).padStart(2, "0");
    var mesCurr = String(primeiroDiaDoMes.getMonth() + 1).padStart(2, "0"); // Os meses em JavaScript são indexados a partir de 0
    var anoCurr = primeiroDiaDoMes.getFullYear();

    var YaerCurrent = new Date(`${anoCurr}/${mesCurr}/${diaCurr}`);

    var difdateCurrentMill = Math.abs(Currentdate - YaerCurrent);
    var diferenceDaysCurrent = Math.ceil(
      difdateCurrentMill / (1000 * 60 * 60 * 24)
    );

    console.log(diferenceDaysCurrent);

    var diferenceMileconds = Math.abs(DateLimite - Currentdate);

    var diferenceDays = Math.ceil(diferenceMileconds / (1000 * 60 * 60 * 24));

    if (diferenceDays <= diferenceDaysCurrent) {
      console.log("aqui");

      return diferenceDays;
    } else if (diferenceDays > 10 && diferenceDays <= 15) {
      console.log("impresso");
      return diferenceDays;
    } else if (diferenceDays >= 15 && diferenceDays <= 31) {
      console.log("yap");

      return diferenceDays;
    }
  });
});

function Callextractos() {
  router.push("/extractos");
}
function referenciasMensais() {
  router.push("/other_references");
}

function moneyFormat(money) {
  const formatter = new Intl.NumberFormat("MZ", {
    style: "currency",
    currency: "MZN",
  });

  return formatter.format(money);
}

async function printExtracto() {
  const studentId = store.state.auth.user.user_data.student_id;
  const year = store.state.auth.user.user_data.inscription_year;
  const agency = store.state.auth.user.user_data.agency;

  const resp = await axios.post(apiUrl+`getTipoExtracto`,
    { student_id: studentId, year: year }
  );

  const url = `https://gestao.sgpr-ippc.com/SGPR-IPPC/view/control/ExtractoAluno.php?ano=${year}&estu1=${studentId}&ag=${agency}&tipo=${resp.data.type}, 'Impressao' , 'width=900,height=850,scrollbars=yes'`;
  window.open(url);
}

async function generateReceipt(referencesFic, TurmaC) {
  const dserv = await getDesignacaoCurso(TurmaC);
  const dservServico = await getDesignacaoServico(TurmaC);
  var t = "";
  if (dserv == "" || dserv == null) {
    t = dservServico;
  } else {
    t = dserv;
  }

  const url = `https://gestao.sgpr-ippc.com/SGPR-IPPC/view/control/REFERENCIA_PARA_PAGAMENTO.php?ref=${referencesFic}`;
  
  window.open(url);
}

// async function generateReceipt(referencesFic, TurmaC) {
//   const dserv = await getDesignacaoCurso(TurmaC);
//   const dservServico = await getDesignacaoServico(TurmaC);
//   var t = "";
//   if (dserv == "" || dserv == null) {
//     t = dservServico;
//   } else {
//     t = dserv;
//   }

//   const url = `https://gestao.jdesign.co.mz/SGPR-IPPC/view/control/RECIBO.php?ref=${referencesFic}&dserv=${t}`;
//   window.open(url);
// }

async function getDesignacaoCurso(TurmaC) {
  const resp = await axios.get(apiUrl+`getDesignacaoCurso/${TurmaC}`
  );

  if (resp.data.result == null) {
    return null;
  } else {
    return resp.data.result.designacao;
  }
}

async function getDesignacaoServico(TurmaC) {
  const resp = await axios.get(apiUrl+`getDesignacaoServico/${TurmaC}`
  );

  if (resp.data.result == null) {
    return null;
  } else {
    return resp.data.result.Descricao_servico;
  }
}

function diferenceBetweenDates(dataLimite) {
  //   const dataAtual = new Date();
  const dataAtual = new Date("2023/02/01");

  var dia = String(dataAtual.getDate()).padStart(2, "0");
  var mes = String(dataAtual.getMonth() + 1).padStart(2, "0"); // Os meses em JavaScript são indexados a partir de 0
  var ano = dataAtual.getFullYear();

  //   const Currentdate = new Date(`${mes}/${dia}/${ano}`);
  const Currentdate = new Date(`${ano}/${mes}/${dia}`);
  const DateLimite = new Date(dataLimite);

  var diferenceMileconds = Math.abs(Currentdate - DateLimite);

  var diferenceDays = Math.ceil(diferenceMileconds / (1000 * 60 * 60 * 24));

  console.log(diferenceDays);
  return diferenceDays;
}

function getMonth(mes) {
  switch (mes) {
    case 1:
      return "Janeiro";
    case 2:
      return "Fevereiro";
    case 3:
      return "Março";
    case 4:
      return "Abril";
    case 5:
      return "Maio";
    case 6:
      return "Junho";
    case 7:
      return "Julho";
    case 8:
      return "Agosto";
    case 9:
      return "Setembro";
    case 10:
      return "Outubro";
    case 11:
      return "Novembro";
    case 12:
      return "Dezembro";
    default:
      return "";
  }
}
</script>
 

 <style scoped>
.facturaNaoPaga {
  background: #fc5454;
  color: #ffffff;
}
</style>