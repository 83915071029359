<template>
  <div class="authincation h-100">
    <div class="container h-100">
      <div class="car col-lg-8 mx-auto" style="margin-top:12em;">
        <div class="row justify-content-center h-100 p-5 align-items-center">
          <div class="card col-md-6 bg-white">
            <div class="authincation-content">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="auth-form">
                    <!-- <h4 class="text-center mb-4">Acesse a sua conta</h4> -->
                    <div class="text-center" id="logo">
                      <img src="assets/images/externato.png"  alt="" >
                    </div>
                    <form @submit.prevent="handleSubmitButton">
                      <div class="form-group">
                        <label class="ml-3"><strong>Username</strong></label>
                        <input
                          type="text"
                          class="form-control col-lg-11 mx-auto"
                          v-model="data.username"
                        />
                      </div>
                      <div class="form-group">
                        <label class="ml-3"><strong>Senha</strong></label>
                        <input
                          type="password"
                          class="form-control col-lg-11 mx-auto"
                          v-model="data.passwd"
                        />
                      </div>

                      <div class="form-group">
                        <label class="ml-3"><strong>Ano</strong></label>
                        <select
                           class="form-control col-lg-11 mx-auto"
                          v-model="data.yearLog"
                        >
                          <option :value="y" v-for="(y, index) in years" :key="index">{{y}}</option>
                        
                        </select>
                      </div>
                      <div
                        class="form-row d-flex justify-content-between mt-4 mb-2 col-lg-11"
                      >
                        <div class="form-group">
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="basic_checkbox_1"
                            />
                            <label
                              class="custom-control-label"
                              for="basic_checkbox_1"
                              >Lembrar-me</label
                            >
                          </div>
                        </div>
                        <div class="form-group">
                          <router-link to="/forgot-password"
                            >Esqueceu a senha?</router-link
                          >
                        </div>
                      </div>
                      <div class="text-center">
                        <button
                          type="button mx-auto"
                          class="btn-block"
                          style="
                            background-color: #ed7431;
                            color: #fff;
                            border: none;
                            border-radius: 6px;
                            padding: 8px;
                          "
                          v-on:click="handleSubmitButton"
                        >
                          {{ loading == false ? "Acessar" : "Acessando..." }}
                        </button>
                      </div>
                    </form>
                    <div class="new-account mt-3">
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import toastr from "toastr";
import axios from "axios";
export default {

  data() {
    return {
      data: {
        // email: 'admin@ispocel.ac.mz',
        username: "",
        // password: 'adm1n@1234'
        passwd: "",
        yearLog: new Date().getFullYear()
      },

      years:[],
      loading: false,
    };
  },

  methods: {
    ...mapActions({
      signIn: "auth/login",
      loggedYear: "auth/loggedYear",
    }),

    getYear(){
        const currentYear = new Date().getFullYear();

        for (let year = currentYear; year >= 2000 ; year--) {
             
             this.years.push(year);
             
        }
    },

    async handleSubmitButton() {
      this.loading = true;
       const apiUrl = process.env.VUE_APP_API_URL;

      axios
        .post(`https://portal.sgpr-ippc.com/Api/api/login`, this.data)
        // .post(`http://127.0.0.1:8000/api/login`, this.data)
        .then(({ data }) => {

 
          if(data.login == false){
            toastr.error("Usuário ou senha inválida ");

          }else{
            localStorage.setItem("user", JSON.stringify(data));
            toastr.success("Bem vindo!! acedeu com sucesso");
            this.signIn(data);
            this.loggedYear(this.data.yearLog)

          }
        })
        .catch((response) => {
          toastr.error(
            "Erro de autenticacao verifique suas credenciais ou contacte o administrador" +
              response.data.message
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created(){
    this.getYear()
  }
};
</script>


<style scoped>
#logo img {
   margin: 0 auto;
  padding: 3px;
  width: 120px;
  height: 120px;
   object-fit: cover;
}
</style>