<template>
  <footer class="footer">
    © 2021
       -  Prosofty
  </footer>
</template>

<script>
export default {};
</script>
