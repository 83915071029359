import axios from "axios";
// import router from "@/router";
export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: {},
    LoggedYear:null,
  },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },

    LoggedYear(state){
      return state.LoggedYear;
    }
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },

    SET_LOGGEDYEAR(state, value){

      state.LoggedYear = value;
    }
  },
  actions: {
    login({ commit }, data) {
      console.log("see", data.user_data)
      const apiUrl = process.env.VUE_APP_API_URL;



      axios
        .get(`https://portal.jdesign.co.mz/Api/api/user/`+data.user_data.student_id)
        .then(({ data }) => {
          commit("SET_USER", data);
          commit("SET_AUTHENTICATED", true);
           // window.location.href = "/dashboard"
          console.log(data);
            if(data.role== "Superadmin"){
              window.location.href = "/dashboard"

            }else{ 
              window.location.href = "/"
            } 
          
        })
        .catch(() => {
          commit("SET_USER", {});
          commit("SET_AUTHENTICATED", false);
        });
    },

    loggedYear({commit}, yearlogged){
      commit("SET_LOGGEDYEAR", yearlogged)
    },

    logout({ commit }) {
      commit("SET_USER", {});
      commit("SET_AUTHENTICATED", false);
    },
  },
};
